import React from 'react'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import styled from 'styled-components/macro'
import {Cookies, getCookie, removeCookie} from 'typescript-cookie'
import {useTranslation} from 'react-i18next'
import i18next from 'i18next'
import LanguageIcon from '@mui/icons-material/Language'
import {Box} from '@mui/material'

import BalanceCards from '../../features/BalanceCards/BalanceCards'
import {useAppDispatch, useAppSelector} from '../../hooks/redux'
import {AuthorizationStatus} from '../../libs/consts'
import {setAuthorizationStatus} from '../../store/slices/base-slice'
import Container from '../container/container'
import Currencies from '../currencies/currencies'
import logoImg from '../../assets/images/logo.svg'
import {useLazyLogoutQuery} from '../../store/services/base-service'
import MobileMenu from '../mobileMenu/MobileMenu'


const HeaderWrapper = styled.div`
`

const HeaderContainer = styled(Container)`
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Logo = styled.div`
	text-transform: uppercase;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	letter-spacing: 0.04em;
	color: #000;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 7px;
`

const LogoTitle = styled.h1`
	font-family: 'Audiowide';
	font-size: 25px;
	line-height: 140%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: #000;
`

const LogoSubTitle = styled.h2`
	font-style: normal;
	font-weight: 500;
	font-size: 5px;
	line-height: 6px;
	letter-spacing: 0.395em;
	text-transform: uppercase;
	color: #BDBDBF;
`

const Links = styled.div`
	display: flex;
	align-items: center;
	gap: 24px;
	margin-left: auto;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	letter-spacing: 0.04em;
	color: #989A9E;
`

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const LogoContent = styled.div`
    margin-left: 20px;
	display: flex;
	align-items: center;
	color: #989A9E;

	@media screen and (max-width: 540px) {
		display: none;
	}
`

const Username = styled.div`
	margin-left: 16px;
	font-weight: 600;
	font-size: 16px;
	line-height: 140%;
	letter-spacing: 0.04em;
	color: #989A9E;
`

const Cards = styled.div`
   margin: 0 16px;
`

const Right = styled.div`
    margin-right: 20px;
	display: flex;
	align-items: center;
	gap: 24px;

	@media screen and (max-width: 768px) {
		margin-left: 20px;
		margin-right: 5px;
	}

	@media screen and (max-width: 590px) {
		margin-left: 5px;
 		margin-right: 5px;
		gap: 5px;
	}

	@media screen and (max-width: 540px) {
		width: 100%;
	}
`

const HeaderLink = styled.div<{ isActive: boolean }>`
    margin: 2px;
	width: max-content;
	transition: all 0.3s ease 0s;
	color: ${({ isActive }) => isActive ? '#7154DC' : '#989A9E'};
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	@media screen and (max-width: 768px) {
		color: ${({ isActive }) => isActive ? '#7154DC' : '#4D4E52'};
	}
`

const TopWrapper = styled.div`
	background: #fff;
`

const CryptoButton = styled.button`
    height: 32px;
    background: #7154DC;
    border-radius: 6px;
    padding: 8px;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #fff;
	white-space: nowrap;

    @media screen and (max-width: 330px) {
        min-height: 32px;
        line-height: 10px;
        font-size: 8px;
        word-wrap: break-word;
    }
`

const Header = () => {
	const username = Cookies.get('usernamelkxp')?.toString()
	const windowWidth = window.innerWidth

	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const {t, i18n} = useTranslation()

	const {
		tx_operations_access,
		balance_access,
		statistics_access,
		transactions_access,
		invoice_access,
		withdrawal_access,
		conversion_access,
		orders_access
	} = useAppSelector((state) => state.base.accesses)
	const isSubUser = useAppSelector(state => state.base.isSubUser)
	const [logout] = useLazyLogoutQuery()

	const exit = () => {
		dispatch(setAuthorizationStatus(AuthorizationStatus.NO_AUTH))
		logout()
		navigate('/auth')
		if (window.location.host === '157.230.31.149:12003') {
			removeCookie('access_token')
			removeCookie('refresh_token')
		} else {
			removeCookie('access_token', { domain: '.expay.cash' })
			removeCookie('refresh_token', { domain: '.expay.cash' })
		}
		removeCookie('usernamelkxp')
		removeCookie('is_fiat')
		Cookies.set('isAuthlkxp', AuthorizationStatus.NO_AUTH)
		window.location.reload()
	}

	return (
		<HeaderWrapper>
			<TopWrapper>
				<HeaderContainer>
					<Content>
						<LogoContent>
							<Logo>
								<img src={logoImg} alt="logo" />
								<NavLink to="/">
									<LogoTitle>EXPAY</LogoTitle>
									<LogoSubTitle>universe of payments</LogoSubTitle>
								</NavLink>
							</Logo>
							{windowWidth > 860 && (
								<>
									<span style={{ marginLeft: 16 }}>|</span>
							  		<Username>{username}</Username>
								</>
							)}
						</LogoContent>
						<Right>
							<Currencies />
							<Box
								onClick={() => i18n.changeLanguage(i18next.language === 'en' ? 'ru' : 'en')}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-around',
									cursor: 'pointer',
									height: '55px',
									'@media (max-width: 980px)': {
										width: '40px',
									},
									'@media (max-width: 960px)': {
										marginLeft: '0',
									},
								}}
							>
								<LanguageIcon sx={{height: '30px', width: '30px', color: '#989A9E'}}
								/>
							</Box>
							<CryptoButton onClick={() => window.open('https://crypto.expay.cash/', '_self')}>
								EXPAY CRYPTO
							</CryptoButton>
							{windowWidth > 1130 ? (
								<>
									<Links>
										{isSubUser ?
											<>
												{!!(tx_operations_access || balance_access || statistics_access || orders_access) &&
													<HeaderLink key={'/'} isActive={pathname === '/'}>
														<NavLink to={'/'}>
															{t('components.main')}
														</NavLink>
													</HeaderLink>
												}
												{!!(transactions_access || invoice_access || withdrawal_access) &&
													<HeaderLink isActive={pathname === '/balance'}>
														<NavLink to={'/balance'}>
															{t('components.balance')}
														</NavLink>
													</HeaderLink>
												}
												{(getCookie('is_fiat') === 'true' && !!conversion_access) && (
													<HeaderLink key="/conversions"
																isActive={pathname === '/conversions'}>
														<NavLink to="/conversions">
															{t('components.conversions')}
														</NavLink>
													</HeaderLink>
												)}
											</>
											:
											<>
												<HeaderLink key={'/'} isActive={pathname === '/'}>
													<NavLink to={'/'}>
														{t('components.main')}
													</NavLink>
												</HeaderLink>
												<HeaderLink isActive={pathname === '/balance'}>
													<NavLink to={'/balance'}>
														{t('components.balance')}
													</NavLink>
												</HeaderLink>
												{getCookie('is_fiat') === 'true' && (
													<HeaderLink key="/conversions"
																isActive={pathname === '/conversions'}>
														<NavLink to="/conversions">
															{t('components.conversions')}
														</NavLink>
													</HeaderLink>
												)}
												<HeaderLink key="/settings" isActive={pathname === '/settings'}>
													<NavLink to="/settings">
														{t('components.settings')}
													</NavLink>
												</HeaderLink>
											</>
										}
									</Links>
									<div style={{ color: '#7154DC', cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={exit}>
										{t('components.logout')}
									</div>
                        		</>
							)
							: (
								<MobileMenu pathname={pathname} HeaderLink={HeaderLink} exit={exit} />
							)}
						</Right>
					</Content>
				</HeaderContainer>
			</TopWrapper>
			<HeaderContainer>
				{isSubUser ?
					(!!balance_access || !!statistics_access) &&
					<Cards>
						<BalanceCards/>
					</Cards>
					:
					<Cards>
						<BalanceCards />
					</Cards>
				}


			</HeaderContainer>
		</HeaderWrapper>
	)
}

export default React.memo(Header)
