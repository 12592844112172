//@ts-nocheck
import axios, { AxiosRequestConfig } from 'axios'

// export const BASE_API_URL = 'https://lk.expay.cash/'
// export const BASE_API_URL_CRYPTO = 'https://lk.expay.cash/'

export const BASE_API_URL = 'https://testenv3.expay.cash'
export const BASE_API_URL_CRYPTO = 'https://testenv3.expay.cash'

export const api = axios.create({
	baseURL: BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
})

const requestHandler = (
	config: AxiosRequestConfig<string | number | boolean>,
) => {
	return config
}

api.interceptors.request.use(
	(config: AxiosRequestConfig<string | number | boolean>) =>
		requestHandler(config),
)
